.time-column {
  width: 100px;
  margin-right: 2px;
}

.time-cell {
  height: 30px;
  width: 100%;
  background-color: rgb(244, 246, 255);
  text-align: center;
  border-bottom: solid 1px rgb(223, 223, 223);
  color: grey;
}

.detail-comtainer {
  max-width: 300px;
  margin: auto;
}

.long-detail-comtainer {
  margin: auto;
}

.device-name-title {
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 85px;
  font-size: 25px;
}

.device-status-img {
  background-color: white;
  width: 90px;
  height: 90px;
  margin: 0 auto 20px auto;
  display: flex;
  border-radius: 45px;
  justify-content: center;
  align-items: center;
  border-style: dotted;
  border-width: 1px;
  box-shadow: 0px 2px 5px 1px rgb(165, 165, 165);
  border-color: rgb(184, 184, 184);
}

.routines-box {
  width: 100%;
  height: 100px;
  background-color: rgb(231, 231, 231);
  overflow-y: scroll;
  position: relative;
}

.text-btn {
  color: rgb(192, 192, 192);
}

.text-btn:hover {
  color: rgb(116, 116, 116);
  cursor: pointer;
}

.detail-edit-buttons {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.detail-edit-buttons button {
  margin-right: 10px;
}

.detail-edit-form {
  margin-top: 50px;
  max-width: 300px;
  margin: auto;
}
.inverted {
  filter: invert(1);
}

.mynest-wrapper {
  margin: 100px auto;
  min-height: 1000px;
  max-width: 1000px;
}

.loading-div {
  padding-top: 100px;
  text-align: center;
}

.spaced-div {
  margin-bottom: 50px;
}

.loading-animate {
  width: 20px;
  height: 20px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
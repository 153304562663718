.floating-menu {
  position: absolute;
  z-index: 3;
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  min-width: 200px;
  max-width: 300px;
  margin-top: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.floating-menu:hover {
  box-shadow: 0px 0px 2px 3px rgb(167, 167, 167);
}

.menu-tab {
  display: flex;
  align-items: center;
}

.grouping-0 {
  font-size: 10px;
  color: rgb(172, 172, 172);
  height: 13px;
  overflow-y: hidden;
}

.grouping-1 {
  font-size: 12px;
  color: rgb(117, 117, 117);
  height: 15px;
  overflow-y: hidden;
}

.grouping-2 {
  font-size: 20px;
  color: rgb(48, 48, 48);
  height: 25px;
  overflow-y: hidden;
}

.add-section-btn {
  margin: 10px 0px;
  font-size: 13px;
  display: flex;
  color: grey;
}

.add-section-btn:hover {
  background-color: grey;
  color: rgb(255, 255, 255);
}

.add-subsection-btn {
  width: 100%;
  height: 2px;
  background-color: white;
  color: white;
  font-size: 13px;
}

.add-subsection-btn:hover {
  height: 10px;
  background-color: white;
  color: rgb(192, 192, 192);
}

.shade-div {
  position: absolute;
  width: 100%;
  height: 93vh;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.438);
  top: 0;
}

.detail-box {
  max-width: 870px;
  /* height: 50vh; */
  /* max-height: 400px; */
  background-color: white;
  margin: 20px auto;
  border-radius: 5px;
  position: relative;
  padding: 10px;
}

.prop-wrapper {
  max-width: 220px;
  margin: auto;
}

.close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  margin: 5px;
  width: 40px;
  height: 40px;
  padding: 4px;
  border-radius: 20px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.8);
  background-color: white;
  cursor: pointer;
}
.close-btn:hover {
  box-shadow: 0 5px 7px 0 rgba(60, 66, 87, 0.8);
}

.more-btn {
  padding: 0 5px;
}
.more-btn:hover {
  box-shadow: 0px 0px 2px 0 rgba(60, 66, 87, 0.8);
  border-radius: 5px;
}

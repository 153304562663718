.weekly-schedule__day-columns {
  display: flex;
  justify-content: center;
}

.weekly-schedule__title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(76, 76, 76);
  margin-bottom: 20px;
}

.weekly-schedule__day-labels {
}

.menu-item{
 color:grey;
}
.menu-item:hover{
	background-color: rgb(221, 221, 221);
}

.sub-menu{
	margin-left: 10px;
}

.add-section-btn{
	margin-bottom: 20px;

}
.add-subsection-btn{
	margin-bottom: 20px;

}
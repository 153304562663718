.device-map-details{

	background-color: white;
	border-radius: 10px;
	position: absolute;
	z-index: 5;
	margin-left: 20px;
	margin-top: 60px;
	min-height: 100px;
	min-width: 100px;

}

.my-map{
	margin-top: 65px;
}

.map-lbl{
	padding: 5px;
	border-radius: 5px;
	background-color: white;
}

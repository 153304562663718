.day-program {
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
}

.day-program:hover {
  border: solid 1px rgb(175, 175, 175);
}

.day-program--darker {
  background-color: rgb(221, 221, 221);
}

.day-program__events__item {
  position: absolute;
  width: 100%;
  background-color: #67bfff;
  border-radius: 3px;
  border: solid 1px white;
  cursor: pointer;
}

.day-program__events__item__content__labels {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.day-program__events__item__delete-button {
  display: none;
}

.day-program__events__item__delete-button .icon-inner svg path {
  fill: green;
}

.day-program__events__item__content:hover .day-program__events__item__delete-button {
  z-index: 600;
  position: absolute;
  top: -20px;
  right: -10px;
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240);
}

.day-program__events__item:hover {
  background-color: #9dd3f9;
}

.day-program__wrapper {
  position: relative;
  height: 360px;
  width: 100px;
  margin-right: 2px;
  overflow: hidden;
}

.day-program__events {
  position: absolute;
  width: 90%;
}
.day-program__events__item__time-label {
  font-size: 10px;
  margin-left: 2px;
  color: white;
}

.day-program__events__item__duration-label {
  font-size: 11px;
  margin-right: 2px;
  color: white;
}

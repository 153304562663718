.event-details-box__background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.216);
}

.event-details-box {
  margin: 100px auto;
  border-radius: 5px;
  width: 300px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-details-box__title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.event-details-box__title h5 {
  margin: 0 10px;
}

.event-details-box__item {
  display: flex;
  margin: 10px 0px;
}
.event-details-box__item__label {
  display: flex;
  width: 90px;
  align-items: center;
  margin-right: 20px;
}

.event-details-box__item__label h6 {
  margin: 0 5px;
}

.event-details-box__item__time-input {
  display: flex;
  align-items: center;
}

.event-details-box__item__time-input input {
  width: 50px;
  height: 30px;
  text-align: center;
  margin: 0 5px;
}

.event-details-box__item__duration-input {
  display: flex;
  align-items: center;
}

.event-details-box__item__duration-input h6 {
  margin: 0 5px;
}

.event-details-box__item__duration-input input {
  width: 50px;
  height: 30px;
  text-align: center;
  margin: 0 5px;
}

.event-details-box .btn {
  margin-top: 30px;
}

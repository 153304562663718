.group-title {
    margin-left: 20px;
    margin-top: 20px;
  }
  .group-btns-wrapper {
    min-width: 220px;
    max-width: 265px;
    margin-bottom: 20px;
    display: inline-block;
  }
  .group-btn {
    border-radius: 10px;
    font-size: 13px;
    color: white;
    background-color: rgba(23,161,165,0.39);
    border-color: white;
  }
  .group-btn:hover {
    background-color: rgba(23,161,165);
    border-color: white;

  }
  .group-edit {
    margin-left: 15px;
    text-align: center;
    max-width: 500px;
  }
  .group-title-edit {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    display: inline-block;
  }
  .group-box{
  background-color: rgb(77,183,187,0.2);
  box-shadow: 1px 1px 1px 1px #d0c8c8;
  border-radius: 20px;
  margin-bottom: 20px;
  }
  
  .group-flex-blox {
    min-width: 100%;
  }
  
  .add-group-div {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    min-height: 40px;
  }
  .add-group-div:hover {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    color: rgba(23,161,165,0.7);
    box-shadow: 5px 5px 5px 5px #e0e0e0;
  }
  .new-group-title {
    margin-top: 5px;
    width: 265px;
  }
  
  .add-group-btns-wrapper {
    min-width: 220px;
    max-width: 265px;
    display: inline-block;
  }
  
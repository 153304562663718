.device {
  height: 100px;
  width: 220px;
  background-color: #fafafa;
  box-shadow: 2px 2px #e0e0e0;
  margin: 10px 10px 10px 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  box-shadow: 2px 2px 2px 2px #d0c8c8;
  min-width: 220px;
  max-width: 220px;
}

.device:hover {
  box-shadow: 2px 2px 2px 4px #d0c8c8;
}

.device-extended {
  height: 380px;
  width: 300px;
  background-color: #fafafa;
  margin: 10px 10px 10px 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  box-shadow: 2px 2px 2px 2px #d0c8c8;
  min-width: 220px;
  max-width: 220px;
}

.device-blocked {
  border-left: 0.25rem solid #f83434 !important;
}

.device-offline {
  box-shadow: 1px 1px 0px 0px #d0c8c8;
  -webkit-filter: brightness(0.9);
  filter: brightness(0.9);
}

.device-online {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}

.device-on {
  border-left: 0.25rem solid rgb(72, 218, 70, 0.4) !important;
}

.device-btns {
  margin-top: 3px;
  height: 44px;
  margin-bottom: 32px;
}

.view-btn {
  width: 50%;
  margin-right: 22px;
  border-radius: 10px;
  font-size: 13px;
  background-color: rgba(23, 161, 165);
  border-color: rgb(255, 255, 255);
}

.view-btn:hover {
  background-color: rgba(23, 161, 165, 0.5);
  border-color: rgb(255, 255, 255);
}

.action-btn {
  width: 50%;
  border-radius: 10px;
  font-size: 13px;
  color: white;
  background-color: rgba(23, 161, 165);
  border-color: white;
  font-size: 12px;
}

.action-btn:hover {
  background-color: rgba(23, 161, 165, 0.5);
  border-color: rgb(255, 255, 255);
}

.action-btn:active {
  background-color: rgba(165, 23, 23, 0.5);
  border-color: rgb(255, 255, 255);
}

.device-name {
  font-size: 14px;
  margin: 0;
  height: 18px;
  overflow-y: hidden;
}

.device-icon {
  z-index: 2;
  position: absolute;
  left: 190px;
  background-color: rgb(255, 255, 255);
  font-size: 20px;
  border-radius: 9px;
}

.device-icon-neutral {
  color: #292929;
}

.device-icon-on {
  color: rgba(23, 161, 165);
}

.device-icon-alert {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 99, 71, 0.473);
}

.device-icon-off {
  color: #b1b1b1;
  filter: grayscale(1);
}

.status-title {
  font-size: 13px;
}

.offline-status {
  color: rgba(0, 0, 0, 0.35);
  font-size: 11px;
}

.online-status {
  color: rgb(160, 208, 160);
  display: inline;
}

.online-mark {
  font-size: 9px;
  display: inline;
  margin-right: 3px;
}

.problem-status {
  color: rgb(205, 22, 22);
  font-size: 12px;
}

.device-info-wrapper {
  height: 275px;
  width: 196px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px;
  background-color: rgb(237, 237, 237);
  margin-bottom: 5px;
}

.device-info-label {
  color: rgb(54, 49, 49);
  margin: auto 15px auto 5px;
  font-size: 13px;
  display: block;
}

.device-info-line {
  margin: 10px 0;
}

.device-info-value {
  color: rgb(54, 49, 49, 0.6);
  font-size: 16px;
  width: 70%;
  text-align: center;
  display: block;
}

.device-id {
  color: green;
  margin: auto 0px;
  font-size: 10px;
  text-align: center;
}

.device-info-wrapper {
  height: 275px;
  width: 196px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px;
  background-color: rgb(237, 237, 237);
  margin-bottom: 5px;
}

.slidecontainer {
  width: 100%;
  margin: 10px 0px;
}

.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 10px;
  /* Specified height */
  background: #d3d3d3;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 40px;
  /* Set a specific slider handle width */
  height: 40px;
  /* Slider handle height */

  background-image: url("/img/slider.svg");
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 40px;
  /* Set a specific slider handle width */
  height: 40px;
  /* Slider handle height */
  background: #4caf50;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.info-title {
  margin-top: 5px;
  font-size: 16px;
}

.device-type-select {
  width: 196px;
  margin-bottom: 10px;
}

.add-device-div {
  font-size: 20px;
}

.add-device-title {
  font-size: 20px;
}

.add-device {
  -webkit-filter: opacity(0.6);
  filter: opacity(0.6);
}

.add-device-extended {
  -webkit-filter: opacity(1);
  filter: opacity(1);
}

.add-device:hover {
  cursor: pointer;
  box-shadow: 5px 5px 5px 5px #d0c8c8;
}

.new-device-title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.device-feature {
  align-items: center;
  margin: 10px auto;
}

.feature-value {
  margin: 0px 5px;
}

.feature-value-lbl {
  font-size: 12px;
  margin: auto;
}

.feature-value-title {
  font-size: 16px;
  margin: auto;
}

.check-logs-lbl {
  cursor: pointer;
  color: rgb(170, 190, 170);
  text-align: center;
  font-size: 15px;
}

.rgb-pallete {
  padding-left: 25px;
  height: 100px;
  font-size: 30px;
}

.rgb-color {
  margin: 0px;
  padding: 0px;
  max-width: 50px;
}

.rgb-color-crcl:hover {
  box-shadow: 5px 5px 5px 2px #d0c8c8;
  border-radius: 15px;
}

.logs-wrapper {
  height: 250px;
  padding: 10px 10px;
}

.log {
  font-size: 11px;
}

.log-date {
  font-size: 8px;
  color: rgb(200, 200, 200);
  margin-bottom: 0px;
}

.big-icon {
  font-size: 55px;
}

.settings-form {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 113px;
  width: 98%;
  margin: auto;
  background-color: #e0e0e0;
}

.settings-box {
  position: relative;
  width: 212px;
}

.save-settings-btn {
  position: absolute;
  bottom: -30px;
  right: 20px;
  background-color: rgba(23, 161, 165);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 5px 5px 5px 2px #d0c8c8;
}

.save-settings-btn:hover {
  box-shadow: 2px 2px 5px 2px #d0c8c8;
  border-style: solid;
  border-width: 1px;
  border-color: white;
}

.oven-box {
  height: 113px;
  width: 98%;
  margin: auto;
  background: rgb(217, 217, 217);
  background: linear-gradient(180deg, rgba(217, 217, 217, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(128, 128, 128, 1) 100%);
  border-radius: 5px;
  position: relative;
}

.oven-handle {
  height: 10px;
  width: 160px;
  background-color: #414141;
  margin: 2px auto;
  border-radius: 5px;
  box-shadow: 0px 3px 2px 1px #d0c8c8;
}

.oven-window {
  height: 35px;
  width: 50px;
  background-color: #414141;
  border-radius: 3px;
  position: absolute;
  top: 33px;
  left: 15px;
  text-align: center;
}

.oven-window.oven-window-ok {
  background-color: #074c07;
  background: radial-gradient(circle, rgba(7, 76, 7, 1) 0%, rgba(0, 0, 0, 1) 100%);
  color: rgba(255, 255, 255, 0.493);
  display: flex;
  justify-content: center;
  align-items: center;
}

.oven-window.oven-window-alarm {
  background-color: #ab0000;
  background: radial-gradient(circle, rgba(190, 19, 19, 1) 56%, rgba(0, 0, 0, 1) 100%);
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.oven-control-box {
  position: absolute;
  top: 31px;
  left: 70px;
  justify-content: space-around;
  width: 110px;
}

.oven-control {
  text-align: center;
}

.oven-flame-count {
  margin-left: 5px;
  font-size: 12px;
}

.oven-rst-btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  filter: opacity(0);
}

.oven-rst-btn:hover {
  box-shadow: 0px 1px 3px 3px #4a4a4a;
  background-color: white;
  filter: opacity(1);
}

.oven-rst-flames-btn {
  position: absolute;
  top: 34px;
  left: 73px;
}

.oven-rst-open-count-btn {
  position: absolute;
  top: 34px;
  left: 106px;
}

.oven-config-btn {
  position: absolute;
  top: 75px;
  left: 24px;
  font-size: 25px;
  color: #d3d3d3;
  cursor: pointer;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 15px;
}

.oven-config-btn:hover {
  box-shadow: 0px 1px 3px 3px #4a4a4a;
  background-color: white;
  color: #414141;
}

.oven-door-open-msg {
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: rgb(148, 91, 5, 0.2);
  border-radius: 5px;
  padding: 2px;
  color: #ffffff57;
}

.settings-icon {
  font-size: 30px;
}

.settings-input {
  width: 100%;
  height: 25px;
  margin-top: 6px;
}

.settings-input-big {
  width: 100%;
  height: 250px;
  margin-top: 6px;
}

.boiler-info {
  font-size: 11px;
}

.boiler-info .col {
  margin: 5px;
  padding: 0;
}

.boiler-info-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.boiler-info .mini-icon {
  margin-right: 4px;
}
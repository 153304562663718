#Dashboard{
    margin-bottom: 90px;
}
.dashboard-card {
    background-color: rgba(23,161,165);
    height: 100px;
    width: 150px;
    border-radius: 12px;
    margin: 10px;
    padding: 8px;
    color: white;
    box-shadow: 4px 4px 4px 2px #d0c8c8;
  }
  
  .insight-title {
    margin-left: 5px;
  }
  
  .integrations-card {
    height: 150px;
  }
  
  .page-label {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .table-field {
    display: inline-flex;
    font-size: 14px;
    margin-left: 10px;
  }
  
  .table-field-small {
    margin-left: 5px;
    margin-right: 20px;
    min-width: 15px;
  }
  
  .main-label{
    background-color: rgb(130,130,130,0.1);
    min-width: 185px;
  }
  
  .integration-table-field {
    margin-left: 8px;
  }
  
  .admin-card {
    min-height: 33px;
    background-color: rgba(23,161,165,0.2);
    border-radius: 9px;
    padding-top: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }

  

  .icon{
    margin: 3px;
  }
  
  .admin-card-open {
    min-height: 200px;
    background-color: rgba(23,161,165,0.2);
    border-radius: 20px;
    padding-top: 7px;
    text-align: center;
  }

  .dev-inf-section{
    margin: 0px 0px 23px 10px;
    background-color: rgb(130,130,130,0.1);
    align-items: center;
  }
  
  .user-extended-controls {
    width: 90%;
    display: inline-block;
    text-align: center;
  }
  
  .mybtn {
    margin: 3px;
    background-color: rgba(23,161,165) ;
    border-radius: 10px;
    color: white;
    padding: 5px;

  }
  
  .double-field {
    justify-content: center;
  }
  
  .single-field {
    margin-left: 10px;
  }
  
  .field-title {
    color: rgb(133,122,122);
  }
  
  #main {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .insight-value {
    margin-left: 10px;
  }
  
  .admin-wrapper{
    margin-top: 80px;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
  }
  .content{
    max-width: 800px;
    margin: 0 auto;
  }
  .plan-type-select{
    width: 153px;
    margin-left: 10px;
  }
  .plan-valid-until{
    width: 153px;
    margin-left: 10px;
  }
  .plan-basicplus{
  color: rgba(100, 53, 0, 0.7);
  }
  .plan-pro{
  color: rgba(238, 128, 3, 0.7);
  }
  .plan-business{
  color: rgba(0, 47, 255, 0.7);
  }
  
  .icon{
    display: inline-flex;
  }
  .country-flag{
    display:inline-flex;
    margin-left: 10px;
  }

  .lbl-mini{
    font-size: 10px;
    line-height: inherit;
  }
  
  .btn-group-2{
    font-size: 30px;
    display: inline-flex;
    margin-left: 10px;
  }

  .online-dot{
    display:inline
  }
  
  .log-area{
    height: 90%;
    background-color: rgba(23, 160, 165, 0.342) ;
    border-radius: 10px;
   text-align: center;
   margin-right: 10px;
  }
  
  .log-area .row div{
    padding: 0px;
    text-align: left;
  }
  .date-log-lbl{
    margin-left: 17px;
    font-size: 8px;
    line-height: 10px;
  }
  
  .col-6 .log-area .row{
    line-height: 10px;
    margin-bottom: 3px;
  }
  
  .col-6 .row{
    line-height: 10px;
    margin-bottom: 25px;
  }
  
  .search-lbl{
      margin-right: 30px;
      font-size: 20px;
  }

  .users-input{
    width:100%
  }
.navbar.navbar-expand-md {
    background-color: rgb(0,100,104) ;
    padding: 0px;
    /*opacity: 0.8;*/
  }
  
  .navbar-dark .smartnest-logo .navbar-brand {
    color: white;
  }
  
  .navbar-brand {
    display: inline-block;
    margin-right: 1rem;
    font-size: 20px;
    line-height: inherit;
    white-space: nowrap;
    padding: 3px 0px 3px 0px;
  }
  
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(169,158,158,0.9);
  }
  
  .navbar-light .navbar-brand .myicon {
    color: rgba(255,255,255,0.9);
    font-size: 19px;
    line-height: 0;
  }
  
  .navbar-light .navbar-brand {
    color: rgba(255,255,255,0.9);
  }
  
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(162,150,150);
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(255,255,255,0.5);
  }
  
  .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: rgba(255,255,255,0.9);
  }
  
  .navbar-toggler{
    background-color: rgba(255,255,255,0.5);
  }
  
  .navbar-toggler-icon{
    color: rgb(255,255,255);
  }
  
  .nav-link{
    cursor: pointer;
  }
  
  #Logo {
    margin: 10px;
  }
  
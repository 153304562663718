.focus-bg{
	position: absolute;
	background-color: rgba(108, 108, 108, 0.512);
	width: 100%;
	height: 100%;
	z-index: 2;
	border-radius: 10px;
	box-shadow: 0px 0px 5px rgb(171, 171, 171);
	top: 75px;
	left: 0;
}
.focus-box{
	position: relative;
	background-color: white;
	width: 100%;
	height: 100%;
	max-width: 500px;
	max-height: 800px;
	margin: 20px auto;
	z-index: 3;
	border-radius: 10px;
	box-shadow: 0px 0px 5px rgb(171, 171, 171);
	overflow: auto;
	padding: 50px 10px;
}

.focus-info-row{
	display: flex;
	align-items: center;
	background-color: rgba(203, 203, 203, 0.204);
	margin-bottom: 10px;
}

.padding-5{
	padding: 5px;
}
.focus-info-description{
	text-align: left;
	flex-grow: 2;
	padding-left: 10px;
}


.focus-description-title{
color: rgba(128, 128, 128, 0.43);
font-size: 11px;
}
.focus-description-subtitle{
	color: black;
	font-size: 13px;
	
}

.focus-close-btn{
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	border-radius: 5px;
padding: 5px;
}
.focus-close-btn:hover{
	background-color: rgb(183, 183, 183);
	color: white;
}

.focus-info-type-btn{
	margin-bottom: 10px;
	cursor: pointer;
	background-color: grey;
	padding: 5px;
	border-radius: 5px;
	color: white;
	border: 1px solid white;
}
.focus-info-type-btn:hover{
	background-color: white;
	border: 1px solid grey;
	color: grey;
	
}
.focus-info-type-btn.disabled{
	cursor: not-allowed;
	background-color: white;
	border: 1px solid grey;
	color: grey;
	
}
.focus-info-type-btn.selected{
	border: 3px solid #1c6468;
}

.report-button {
  background-color: #4F81BD;
  color: white;
  border: solid 1px #4F81BD;
  border-radius: 5px;
  padding: 20px;
  width: 130px;
  text-align: center;
  margin: 5px;
  font-weight: bold;
}

.report-button:hover {
  background-color: #6189b9;
  cursor: pointer;
}
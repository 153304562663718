.App {
  text-align: center;
}


.login-clean {
  background: linear-gradient(0deg, rgba(23, 161, 165), rgb(77, 183, 187, 0.2));
  padding: 80px 0;
  min-height: 1100px;
}

.login-clean .form {
  max-width: 380px;
  margin: 0 auto;
  padding: 40px 0px;
  background-color: #ffffff;
  border-radius: 70px;
  color: #505e6c;
  box-shadow: 5px 5px 5px 0px rgba(20, 20, 20, 0.493);
}

.login-clean .logo {
  margin-bottom: 20px;
}

.form-field {
  background: rgb(77, 183, 187, 0.2);
  border-bottom: 1px solid #ffe8d6;
  border: none;
  color: inherit;
  outline: none;
  margin: 10px 0px 20px 0px;
  text-indent: 18px;
  height: 42px;
  width: 300px;
  text-align: center;
  box-shadow: 1px 1px 0px 1px #ddd6c1;
  border-radius: 4px;
}

.form-btn {
  background-color: rgba(23, 161, 165);
  border-color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  padding: 11px;
  color: white;
  outline: none;
  margin: 10px 5px;
  width: 200px;
  box-shadow: 0px 3px 0px 0px #ddd6c1;
  display: inline-block;
}

.form-btn:hover {
  background-color: rgba(23, 161, 165, 0.5);
  border-color: rgb(255, 255, 255);
}

.create {
  margin-top: 10px;
  padding: 50px 0px;
}

.filter-input {

  padding: 0px;
  margin: auto 0px;
}

.filter-title {
  text-align: left;
}

.logino-open {

  position: relative;
  -webkit-animation-name: opening;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s;
  /* Safari 4.0 - 8.0 */
  animation-name: opening;
  animation-duration: 1.5s;
  animation-iteration-count: 1;

}

@keyframes opening {
  from {
    height: 100px;
  }

  to {
    height: 170px;
  }
}

.logo-grow {

  position: relative;
  -webkit-animation-name: grow;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s;
  /* Safari 4.0 - 8.0 */
  animation-name: grow;
  animation-duration: 2s;
  animation-iteration-count: 1;

}

@keyframes grow {
  0% {
    height: 100px;
  }

  50% {
    height: 150px;
  }

  100% {
    height: 148px;
  }
}

.my-graph {
  height: 320px;
}

#title {
  margin-bottom: 20px;
}

.email-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 11px;
  outline: none;
  margin-top: 30px;
  width: 300px;
  box-shadow: 5px 5px 5px 0px rgba(20, 20, 20, 0.493);
  color: #505e6c;
}
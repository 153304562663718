.day-labels {
  display: flex;
  justify-content: center;
}

.day-labels__day {
  width: 100px;
  background-color: rgb(125, 125, 125);
  margin-right: 2px;
  text-align: center;
  color: white;
}

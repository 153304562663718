  
  .footer-basic .copyright {
    margin-top: 15px;
    text-align: center;
    font-size: 10px;
    color: #aaa;
    margin-bottom: 0;
  }
  
  .my-link{
    color:#aaa;
  }
  
  .my-link:hover{
    color:rgb(68, 68, 68);
  }

  .credit-img{
    filter: opacity(50%);
  }